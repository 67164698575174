import mediator from "@tvg/mediator";
import { BaseEventProps, track } from "@urp/amplitude";
import { get } from "lodash";
import {
  MediatorEventType,
  EventProps,
  ModuleEventProps,
  PillClickedData,
  CarouselSelectedData,
  ArticleSelectedViewedData
} from "./types";
import { EVENT_NAME } from "./constants";
import { COMMON_EVENT_NAMES } from "../../constants";

export default () => {
  mediator.base.subscribe(
    MediatorEventType.PILL_CLICKED,
    (data: PillClickedData) => {
      track<EventProps>(EVENT_NAME.PILL_CLICKED, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.eventLabel", ""),
        [BaseEventProps.MODULE]: "Horse racing 101"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.CAROUSEL_SELECTED,
    (data: CarouselSelectedData) => {
      track<EventProps>(EVENT_NAME.CAROUSEL_SELECTED, {
        [BaseEventProps.EVENT_CATEGORY]: "Education",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.tutorialName", ""), // e.g "Live Streaming"
        [BaseEventProps.MODULE]: "Tutorials",
        [ModuleEventProps.EDUCATION_CATEGORY]: "walkthrough",
        [ModuleEventProps.ARTICLE_NAME]: get(data, "payload.tutorialName", "")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.ARTICLE_SELECTED,
    (data: ArticleSelectedViewedData) => {
      track<EventProps>(EVENT_NAME.ARTICLE_SELECTED, {
        [BaseEventProps.EVENT_CATEGORY]: "Education",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.eventLabel", ""),
        [BaseEventProps.MODULE]: get(data, "payload.sectionTitle", ""),
        [ModuleEventProps.EDUCATION_CATEGORY]: get(
          data,
          "payload.sectionTitle",
          ""
        ),
        [ModuleEventProps.ARTICLE_NAME]: get(data, "payload.articleName", "")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.ARTICLE_VIEWED,
    (data: ArticleSelectedViewedData) => {
      track<EventProps>(EVENT_NAME.ARTICLE_VIEWED, {
        [BaseEventProps.EVENT_CATEGORY]: "Education",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.eventLabel", ""),
        [BaseEventProps.MODULE]: get(data, "payload.sectionTitle", ""),
        [ModuleEventProps.EDUCATION_CATEGORY]: get(
          data,
          "payload.sectionTitle",
          ""
        ),
        [ModuleEventProps.ARTICLE_NAME]: get(data, "payload.articleName", "")
      });
    }
  );
};
