import { BaseEventProps } from "@urp/amplitude";

export type EventProps = BaseEventProps;

export enum MediatorEventType {
  LOGIN_WALL = "HEADER_LOGIN_WALL",
  PILL_CLICKED = "HEADER_PILL_CLICKED"
}

export interface PillClickedData {
  type: MediatorEventType.PILL_CLICKED;
  payload: {
    eventLabel: string;
  };
}
