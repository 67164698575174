// @flow
import React, {
  type Element,
  // $FlowFixMe
  type UIEvent,
  // $FlowFixMe
  useCallback,
  // $FlowFixMe
  useState,
  // $FlowFixMe
  useEffect
} from "react";
// $FlowFixMe
import loadable from "@loadable/component";
import type { RouterHistory } from "react-router-dom";
import { attempt, throttle } from "lodash";
import type { Dispatch } from "redux";
import mediator from "@tvg/mediator";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import EmailReferral from "@tvg/email-referral";
import ApproxPayoutHelp from "@tvg/approx-payout-help";
import BrazeContentCards from "@tvg/atomic-ui/_templates/BrazeContentCards";
import MyBetsCounter from "@tvg/atomic-ui/_molecule/MyBetsCounter";
import type { BrazeCardNumber } from "@tvg/types/User";
import Support from "@tvg/support";
import FeatureToggles from "@tvg/toggles";
import { closeMyBets } from "@tvg/my-bets/src/actions";

import type {
  Product,
  Location as TvgLocation,
  Device
} from "@tvg/conf/src/types";
import type { MapFeatureToggles } from "@tvg/types/Product";
import {
  closeHandicappingStoreModal,
  setHandicappingStoreModalHeader
} from "@tvg/shared-actions/HandicappingStore";
import {
  closeMyBetsStandalone,
  closeBetCancelModal,
  closeApproxPayoutModal,
  closeMybetsPastPerformance,
  closeBetSocialShareModal
} from "@tvg/sh-lib-my-bets/redux/actions";

import { hasDirectIntegration, brazeContentCardsRefresh } from "@tvg/braze";

import type { NullaryFn } from "@tvg/types/Functional";
import MyBetsHeader from "@tvg/my-bets-standalone/src/MyBetsHeader";
import { useSelector } from "react-redux";
import parseCAPIMessage from "@tvg/utils/capiUtils";
import {
  getSplashIsOpen,
  getSplashIsLocationRequired
} from "@tvg/location-splash/selectors";
import BetCancel from "./BetCancel";
import {
  closePromosModal,
  closePasswordRecoveryModal,
  closeLoginModal,
  closeDepositsModal,
  closeBetPrefsModal,
  closeNavigationModal,
  closePendingWithdrawalsModal,
  closeFeatureTogglesModal,
  closeSupportModal,
  closeContentCardsModal,
  closeEmailReferralModal
} from "../../actions/modals";

import styles from "../style/styles.css";

import balance from "../../services/balance";
import type {
  TransitionState,
  DepositType,
  PasswordRecoveryType
} from "../../types";

const MyBets = loadable(
  () => import(/* webpackChunkName: 'MyBetsModal' */ "./MyBets")
);
const BetPrefs = loadable(
  () => import(/* webpackChunkName: 'BetPreferencesModal' */ "./BetPreferences")
);
const Deposits = loadable(
  () => import(/* webpackChunkName: 'DepositsModal' */ "./Deposits")
);
const PromosCompModal = loadable(
  () => import(/* webpackChunkName: 'PromosCompModal' */ "./PromosModal")
);
const PasswordRecovery = loadable(
  () =>
    import(/* webpackChunkName: 'PasswordRecoveryModal' */ "./PasswordRecovery")
);
const PendingWithdrawals = loadable(
  () =>
    import(
      /* webpackChunkName: 'PendingWithdrawalsModal' */ "./PendingWithdrawals"
    )
);
const HandicappingStore = loadable(
  () =>
    import(
      /* webpackChunkName: 'HandicappingStoreModal' */ "./HandicappingStore"
    )
);
const BetSocialShare = loadable(
  () => import(/* webpackChunkName: 'BetSocialShareModal' */ "./BetSocialShare")
);

export const MyBetsModal = ({
  isVideoShown,
  isOpen,
  dispatch,
  currentPath,
  history,
  device,
  myBetsStandaloneToggle,
  isPastPerformanceOpen,
  pastPerformanceTitle,
  selectedTab,
  selectedSettledTab,
  activeBetsCounter,
  settledBetsCounter,
  futureBetsCounter,
  activeWageredAmount,
  settledWageredAmount,
  myBetsCounterToggle,
  futureWageredAmount
}: {
  isVideoShown: boolean,
  isOpen: boolean,
  dispatch: Dispatch<*>,
  currentPath: string,
  history: RouterHistory,
  device: Device,
  myBetsStandaloneToggle: boolean,
  isPastPerformanceOpen: boolean,
  pastPerformanceTitle: string,
  selectedTab: string,
  selectedSettledTab: string,
  activeBetsCounter: number,
  settledBetsCounter: number,
  futureBetsCounter: number,
  activeWageredAmount: number,
  settledWageredAmount: number,
  myBetsCounterToggle: boolean,
  futureWageredAmount: number
}) => {
  const isSplashOpen = useSelector(getSplashIsOpen);
  const isSplashLocationRequired = useSelector(getSplashIsLocationRequired);

  const [modalScrollableRef, setModalScrollableRef] = useState(null);

  useEffect(() => {
    const hash = history?.location?.hash || "";
    if (isOpen && !hash.includes("#my-bets")) {
      dispatch(closeMyBetsStandalone());
    }
  }, [history?.location?.hash]);

  const titleBuffer = "Bets";
  const title =
    myBetsStandaloneToggle && isPastPerformanceOpen
      ? pastPerformanceTitle
      : titleBuffer;
  const baseProps = {
    title,
    titleType: "ipp",
    isOpen,
    onClose: () => {
      history.push(currentPath);
      if (myBetsStandaloneToggle) {
        dispatch(closeMyBetsStandalone());
        mediator.base.dispatch({
          type: "MYBETS_CLICKS_X_BUTTON",
          payload: {
            selectedTab,
            selectedSettledTab
          }
        });
      } else {
        dispatch(closeMyBets());
        mediator.base.dispatch({ type: "MYBETS_MODAL_CLOSE" });
      }
    },
    isFullHeight: true,
    hasHeaderBorder: !myBetsStandaloneToggle || isPastPerformanceOpen,
    myBetsStandaloneToggle,
    qaLabel: "modal-myBets",
    onBack: isPastPerformanceOpen
      ? () => {
          dispatch(closeMybetsPastPerformance());
        }
      : undefined
  };

  const [scrollYPosition, setScrollYPosition] = useState(0);

  const setModalScrollableRefCallback = useCallback((node) => {
    setModalScrollableRef(node);
  }, []);

  const MyBetsHeaderComp =
    myBetsStandaloneToggle && !isPastPerformanceOpen ? <MyBetsHeader /> : null;

  const counters = (() => {
    switch (selectedTab) {
      case "SETTLED":
        return { counter: settledBetsCounter, amount: settledWageredAmount };
      case "FUTURES":
        return { counter: futureBetsCounter, amount: futureWageredAmount };
      case "ACTIVE":
      default:
        return { counter: activeBetsCounter, amount: activeWageredAmount };
    }
  })();

  const MyBetsCounterComp =
    myBetsStandaloneToggle && myBetsCounterToggle && !isPastPerformanceOpen ? (
      <MyBetsCounter
        wageredAmount={counters.amount}
        betsCounter={counters.counter}
      />
    ) : null;

  const onMyBetsScroll = throttle(
    (scrollEvent: UIEvent<HTMLElement>) => {
      setScrollYPosition(scrollEvent.currentTarget.scrollTop);
    },
    1000,
    {
      trailing: false
    }
  );

  const props =
    device === "mobile"
      ? {
          ...baseProps,
          animation: "bottom",
          hasOverlay: !isVideoShown,
          hasVideoOffset: isVideoShown,
          onScrollFn: onMyBetsScroll
        }
      : {
          ...baseProps,
          animation: "right",
          hasOverlay: true,
          isFullWidth: false,
          hasRoundedCorners: false,
          offsetTop: 0
        };

  if (isSplashOpen && isSplashLocationRequired) {
    return null;
  }

  return (
    <ModalV2
      {...props}
      setScrollableRef={setModalScrollableRefCallback}
      scrollableRef={{ current: modalScrollableRef }}
      headerChildren={MyBetsHeaderComp}
      headerTitleChildren={MyBetsCounterComp}
    >
      {(transitionState: TransitionState) => (
        <MyBets
          transitionState={transitionState}
          device={device}
          myBetsStandaloneToggle={myBetsStandaloneToggle}
          modalScrollableRef={modalScrollableRef}
          scrollYPosition={scrollYPosition}
        />
      )}
    </ModalV2>
  );
};

export const PromosModal = ({
  isVideoShown,
  isOpen,
  dispatch,
  isLogged,
  currentPath,
  history,
  promoPage,
  device
}: {
  isVideoShown: boolean,
  isOpen: boolean,
  dispatch: Dispatch<*>,
  isLogged: boolean,
  currentPath: string,
  history: RouterHistory,
  promoPage: string,
  device: Device
}) => {
  const baseProps = {
    title: "Promotions",
    titleType: "default",
    isOpen,
    onClose: () => {
      const promosPath = currentPath.split("promo=");
      const currentPage = promosPath[1]
        ? promosPath[0].slice(0, -1)
        : currentPath;
      history.push(currentPage);
      dispatch(closePromosModal());
    },
    isFullHeight: true,
    qaLabel: "modal-promos"
  };

  const props =
    device === "mobile"
      ? { ...baseProps, animation: "bottom", hasVideoOffset: isVideoShown }
      : {
          ...baseProps,
          animation: "fade",
          hasOverlay: true,
          offsetTop: 40,
          offsetBottom: 0,
          offsetLeft: 40,
          offsetRight: 40
        };

  return (
    <ModalV2 {...props}>
      {(transitionState: TransitionState) => (
        <PromosCompModal
          isLogged={isLogged}
          currentPath={currentPath}
          transitionState={transitionState}
          dispatch={dispatch}
          promoPage={promoPage}
          router={{ history }}
        />
      )}
    </ModalV2>
  );
};

export const DepositsModal = ({
  isOpen,
  dispatch,
  currentPath,
  history,
  title,
  depositType,
  accountNumber,
  queryParameters,
  device,
  product,
  isSightlineRedirectModalOpen
}: {
  isOpen: boolean,
  dispatch: Dispatch<*>,
  currentPath: string,
  history: RouterHistory,
  title: string,
  depositType: DepositType,
  accountNumber: number,
  queryParameters: {
    product: Product,
    location: TvgLocation
  },
  device: Device,
  product: Product,
  isSightlineRedirectModalOpen: boolean
}) => {
  useEffect(() => {
    const hash = history?.location?.hash || "";
    if (isOpen && !hash.includes("#deposit")) {
      dispatch(closeDepositsModal());
    }
  }, [history?.location?.hash]);

  const baseProps = {
    title,
    titleType: "default",
    isOpen,
    onClose: () => {
      history.push(currentPath);
      dispatch(closeDepositsModal());
      balance.getBalance(dispatch, accountNumber);

      if (depositType === "quick-deposit") {
        mediator.base.dispatch({ type: "DEPOSITS_CLOSE_QUICK" });
      }
    },
    isFullHeight: true,
    qaLabel: `modal-${depositType}`,
    useFakeInput: device === "tablet" && product === "ios2"
  };

  const props =
    device === "mobile"
      ? { ...baseProps, animation: "bottom" }
      : {
          ...baseProps,
          hasOverlay: true,
          hasRoundedCorners: false,
          ...(depositType === "quick-deposit"
            ? {
                animation: "right",
                isFullWidth: false,
                offsetTop: 0
              }
            : {
                animation: "fade",
                offsetTop: 80,
                offsetBottom: 24,
                offsetLeft: 80,
                offsetRight: 24
              })
        };

  return (
    <ModalV2 {...props}>
      {(transitionState: TransitionState) => (
        <Deposits
          transitionState={transitionState}
          depositType={depositType}
          queryParameters={queryParameters}
          isSightlineRedirectModalOpen={isSightlineRedirectModalOpen}
          dispatch={dispatch}
        />
      )}
    </ModalV2>
  );
};

export const LoginModal = ({
  isOpen,
  dispatch,
  currentPath,
  history,
  loginTemplate,
  device,
  product
}: {
  isOpen: boolean,
  dispatch: Dispatch<*>,
  currentPath: string,
  history: RouterHistory,
  loginTemplate: Element<*>,
  device: Device,
  product: Product
}) => {
  const baseProps = {
    title: "Login",
    titleType: "default",
    isOpen,
    onClose: () => {
      history.push(currentPath);
      // gtm event for close login modal
      mediator.base.dispatch({
        type: "LOGIN_MODAL_OPEN_CLOSE",
        payload: {
          open: false
        }
      });
      dispatch(closeLoginModal());
    },
    isFullHeight: true,
    qaLabel: "modal-login",
    useFakeInput: device === "tablet" && product === "ios2"
  };

  const props =
    device === "mobile"
      ? { ...baseProps, animation: "bottom" }
      : {
          ...baseProps,
          animation: "fade",
          hasOverlay: true,
          isFullWidth: false,
          isFullHeight: false,
          isFluidWidth: true,
          offsetTop: 0
        };

  return <ModalV2 {...props}>{() => loginTemplate}</ModalV2>;
};

export const PasswordRecoveryModal = ({
  isOpen,
  dispatch,
  currentPath,
  history,
  title,
  recoveryPage,
  queryParameters,
  device,
  product
}: {
  isOpen: boolean,
  dispatch: Dispatch<*>,
  currentPath: string,
  history: RouterHistory,
  title: string,
  recoveryPage: PasswordRecoveryType,
  queryParameters: { [string]: string },
  device: Device,
  product: Product
}) => {
  const baseProps = {
    title,
    titleType: "default",
    isOpen,
    onClose: () => {
      history.push(currentPath);
      dispatch(closePasswordRecoveryModal());
    },
    isFullHeight: true,
    qaLabel: "modal-login",
    useFakeInput: device === "tablet" && product === "ios2"
  };

  const props =
    device === "mobile"
      ? { ...baseProps, animation: "bottom" }
      : {
          ...baseProps,
          animation: "fade",
          hasOverlay: true,
          offsetTop: 40,
          offsetLeft: 40,
          offsetRight: 40
        };

  return (
    <ModalV2 {...props}>
      {(transitionState: TransitionState) => (
        <PasswordRecovery
          transitionState={transitionState}
          recoveryPage={recoveryPage}
          queryParameters={queryParameters}
        />
      )}
    </ModalV2>
  );
};

export const closeTutorialsModal = (
  history: RouterHistory,
  currentPath: string,
  dispatch: Dispatch<*>
) => {
  history.push(currentPath);
  dispatch(closeNavigationModal());
  mediator.base.dispatch({
    type: "MORE_CLOSE_CONTENT",
    payload: {
      destinationMenu: "More Menu",
      module: "tutorials"
    }
  });
};

export const SideSwipeModal = ({
  isOpen,
  dispatch,
  currentPath,
  history,
  title,
  content,
  device,
  isScrollAfterLoad,
  useModalHeaderV3
}: {
  isOpen: boolean,
  dispatch: Dispatch<*>,
  currentPath: string,
  history: RouterHistory,
  title: string,
  content: Element<*>,
  device: Device,
  // eslint-disable-next-line
  isScrollAfterLoad?: boolean,
  useModalHeaderV3?: boolean
}) => {
  const qaLabel = `modal-${title.split(" ")[0]}` || "modal-legalOptions";
  const baseProps = {
    title,
    titleType: "navigation",
    isOpen,
    onClose: () => closeTutorialsModal(history, currentPath, dispatch),
    onBack: () => closeTutorialsModal(history, currentPath, dispatch),
    isFullHeight: true,
    qaLabel,
    useModalHeaderV3
  };

  if (typeof window !== "undefined" && isScrollAfterLoad)
    setTimeout(() => {
      const { hash } = window.location;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView();
        }
      }
    }, 0);

  const props =
    device === "mobile"
      ? { ...baseProps, animation: "right", hasOverlay: false, offsetTop: 0 }
      : {
          ...baseProps,
          animation: "right",
          hasOverlay: true,
          isFullWidth: false,
          hasRoundedCorners: false,
          offsetTop: 0
        };

  return <ModalV2 {...props}>{() => content}</ModalV2>;
};

export const PendingWithdrawalsModal = ({
  isOpen,
  dispatch,
  currentPath,
  history,
  accountNumber,
  device,
  isApp,
  showPendingWithdrawalsV2,
  isCancelModalOpen,
  closeCancelWithdrawal
}: {
  isOpen: boolean,
  dispatch: Dispatch<*>,
  currentPath: string,
  history: RouterHistory,
  accountNumber: number,
  device: Device,
  isApp: boolean,
  showPendingWithdrawalsV2: boolean,
  isCancelModalOpen: boolean,
  closeCancelWithdrawal: NullaryFn<void>
}) => {
  const isMobile = device === "mobile";

  const { cancelModalContent } = useSelector((store) =>
    parseCAPIMessage(store, "capi.messages.pendingWithdraws", {})
  );

  const onClose = () => {
    history.push(currentPath);
    dispatch(closePendingWithdrawalsModal());
    mediator.base.dispatch({
      type: "PENDING_WITHDRAWALS_CLOSE",
      payload: {
        accountId: accountNumber
      }
    });
  };

  const modalPropsTablet = !isMobile
    ? {
        isTitleCenter: !isCancelModalOpen,
        onClose,
        onBack: isCancelModalOpen ? () => closeCancelWithdrawal() : null,
        titleType: "default"
      }
    : {
        onBack: onClose,
        onClose,
        hasCloseButton: false,
        titleType: "navigation"
      };

  const baseProps = {
    title:
      isCancelModalOpen && !isMobile
        ? cancelModalContent.title
        : "Pending Withdrawals",
    isOpen,
    qaLabel: "modal-pendingWithdrawals",
    animation:
      !isMobile && showPendingWithdrawalsV2 ? "bottomFloating" : "right",
    fixedWidth: !isMobile && showPendingWithdrawalsV2 ? "375px" : "100%",
    offsetTop: 0,
    ...modalPropsTablet
  };

  const pendingWithdrawalsV2Props = {
    ...baseProps,
    useModalHeaderV3: true,
    showBottomShadow: false
  };

  const modalProps = showPendingWithdrawalsV2
    ? pendingWithdrawalsV2Props
    : baseProps;

  const props = isMobile
    ? { ...modalProps, hasOverlay: false, isFullHeight: true }
    : {
        ...modalProps,
        hasOverlay: true,
        isFullWidth: false,
        hasRoundedCorners: showPendingWithdrawalsV2,
        isFullHeight: !showPendingWithdrawalsV2
      };

  return (
    <ModalV2 {...props}>
      {(transitionState: TransitionState) => (
        <PendingWithdrawals
          transitionState={transitionState}
          history={history}
          device={device}
          isApp={isApp}
        />
      )}
    </ModalV2>
  );
};

export const FeatureTogglesModal = ({
  dispatch,
  isOpen,
  device,
  history,
  currentPath
}: {
  dispatch: Dispatch<*>,
  isOpen: boolean,
  device: string,
  history: RouterHistory,
  currentPath: string,
  featureToggles: MapFeatureToggles
}) => {
  const baseProps = {
    title: "Feature Toggles",
    titleType: "default",
    isOpen,
    onClose: () => {
      history.push(currentPath);
      dispatch(closeFeatureTogglesModal());
    },
    isFullHeight: true,
    qaLabel: "modal-featureToggles"
  };

  const props =
    device === "mobile"
      ? { ...baseProps, animation: "bottom" }
      : {
          ...baseProps,
          animation: "right",
          hasOverlay: true,
          isFullWidth: false,
          hasRoundedCorners: false,
          offsetTop: 0
        };

  return <ModalV2 {...props}>{() => <FeatureToggles />}</ModalV2>;
};

export const BetPrefsModal = ({
  isOpen,
  dispatch,
  currentPath,
  history,
  title,
  device
}: {
  isOpen: boolean,
  dispatch: Dispatch<*>,
  currentPath: string,
  history: RouterHistory,
  title: string,
  device: Device
}) => {
  const baseProps = {
    title,
    titleType: "navigation",
    isOpen,
    onClose: () => {
      history.push(currentPath);
      dispatch(closeBetPrefsModal());
      mediator.base.dispatch({
        type: "BET_PREFERENCES:NAVIGATE_MORE_PAGE",
        payload: { tag: "Back button" }
      });
    },
    isFullHeight: true,
    qaLabel: "modal-navigation"
  };

  const closeBetPreferencesModalWithoutGTM = () => {
    history.push(currentPath);
    dispatch(closeBetPrefsModal());
  };

  const props =
    device === "mobile"
      ? { ...baseProps, animation: "right", hasOverlay: false, offsetTop: 0 }
      : {
          ...baseProps,
          animation: "right",
          hasOverlay: true,
          isFullWidth: false,
          hasRoundedCorners: false,
          offsetTop: 0
        };

  return (
    <ModalV2 {...props}>
      {(transitionState: TransitionState) => (
        <BetPrefs
          transitionState={transitionState}
          device={device}
          closeModal={closeBetPreferencesModalWithoutGTM}
        />
      )}
    </ModalV2>
  );
};

export const HandicapStoreModal = ({
  isVideoShown,
  isOpen,
  dispatch,
  currentPath,
  history,
  device,
  product,
  iFrameUrl,
  className,
  hasHeader,
  errorMessage
}: {
  isVideoShown: boolean,
  isOpen: boolean,
  dispatch: Dispatch<*>,
  currentPath: string,
  history: RouterHistory,
  device: Device,
  product: Product,
  iFrameUrl: string,
  className: string,
  hasHeader: boolean,
  errorMessage: string
}) => {
  useEffect(() => {
    const hash = history?.location?.hash || "";
    if (isOpen && !hash.includes("#handicap-store")) {
      dispatch(closeHandicappingStoreModal());
    }
  }, [history?.location?.hash]);

  const baseProps = {
    title: "Handicapping Store",
    titleType: "default",
    hasHeader,
    isOpen,
    onClose: () => {
      history.push(currentPath);
      dispatch(closeHandicappingStoreModal());
      dispatch(setHandicappingStoreModalHeader(true));
    },
    isFullHeight: true,
    qaLabel: "modal-handicapping-store",
    useFakeInput: device === "tablet" && product === "ios2"
  };

  const props =
    device === "mobile"
      ? { ...baseProps, animation: "bottom", hasVideoOffset: isVideoShown }
      : {
          ...baseProps,
          animation: "fade",
          hasOverlay: true,
          offsetTop: 40,
          offsetBottom: 0,
          offsetLeft: 40,
          offsetRight: 40
        };

  return (
    <ModalV2 {...props}>
      {() => (
        <div className={className}>
          <HandicappingStore
            iFrameUrl={iFrameUrl}
            iframeClass={styles.iframeContent}
            dispatch={dispatch}
            errorMessage={errorMessage}
          />
        </div>
      )}
    </ModalV2>
  );
};

export const SupportModal = ({
  isOpen,
  dispatch,
  currentPath,
  history,
  isApp,
  supportLink
}: {
  isOpen: boolean,
  dispatch: Dispatch<*>,
  currentPath: string,
  history: RouterHistory,
  isApp: boolean,
  supportLink: string
}) => {
  useEffect(() => {
    const hash = history?.location?.hash || "";
    if (isOpen && !hash.includes("#support")) {
      dispatch(closeSupportModal());
    }
  }, [history?.location?.hash]);

  const onClose = () => {
    history.push(currentPath);
    dispatch(closeSupportModal());
    mediator.base.dispatch({
      type: "SUPPORT_MODAL_TOGGLE",
      payload: {
        modalToggle: "close"
      }
    });
  };

  const baseProps = {
    title: "Support",
    titleType: "default",
    isOpen,
    onClose,
    isFullHeight: false,
    qaLabel: "modal-support",
    hasShadow: false,
    isApp
  };

  const props = { ...baseProps, animation: "bottom" };
  return (
    <ModalV2 {...props}>
      {(transitionState: TransitionState) => (
        <Support
          currentPath={currentPath}
          transitionState={transitionState}
          dispatch={dispatch}
          liveChatUrl={supportLink}
          isApp={isApp}
          onOpenLiveChat={onClose}
        />
      )}
    </ModalV2>
  );
};

export const ContentCardsModal = ({
  isOpen,
  history,
  currentPath,
  dispatch,
  brazeContentCards,
  brazeMessages,
  contentCardsCustom,
  isApp
}: {
  isOpen: boolean,
  history: RouterHistory,
  currentPath: string,
  dispatch: Dispatch<*>,
  brazeContentCards: BrazeCardNumber,
  brazeMessages: string,
  contentCardsCustom: boolean,
  isApp: boolean
}) => {
  const brazeMessagesParsed = attempt(() => JSON.parse(brazeMessages));
  useEffect(() => {
    const hash = history?.location?.hash || "";
    if (isOpen && !hash.includes("#content-cards")) {
      dispatch(closeContentCardsModal());
    }
  }, [history?.location?.hash]);

  return (
    <BrazeContentCards
      onClose={() => {
        let unviewedContentCardsCount = 0;
        if (hasDirectIntegration()) {
          if (typeof window !== "undefined" && window.braze) {
            window.braze.hideContentCards();

            unviewedContentCardsCount = window.braze
              .getCachedContentCards()
              .getUnviewedCardCount();
          }
        } else {
          brazeContentCardsRefresh();
        }
        history.push(currentPath);
        mediator.base.dispatch({
          type: "OPEN_CLOSE_INBOX",
          payload: {
            open: false,
            unviewedContentCardsCount
          }
        });
        dispatch(closeContentCardsModal());
      }}
      history={history}
      isOpen={isOpen}
      brazeContentCards={brazeContentCards}
      brazeMessages={brazeMessagesParsed}
      contentCardsCustom={contentCardsCustom}
      isApp={isApp}
    />
  );
};

export const BetCancelModal = ({
  isOpen,
  dispatch,
  isLoading,
  device
}: {
  isOpen: boolean,
  dispatch: Dispatch<*>,
  isLoading: boolean,
  device: string
}) => {
  const onClose = () => {
    dispatch(closeBetCancelModal({ result: { status: "aborted" } }));
  };

  const baseProps = {
    title: isLoading ? "Processing Cancellation..." : "Confirm Cancellation",
    titleType: "ipp",
    isOpen,
    onClose,
    qaLabel: "bet-cancel",
    hasShadow: true,
    hasOverlay: true,
    isFullWidth: false,
    isContentTransparent: false,
    isFullHeight: false,
    layerOffset: 1,
    hasContentMaxHeight: false
  };

  const props =
    device === "mobile"
      ? {
          ...baseProps,
          animation: "bottomFloating",
          offsetTop: 10,
          offsetBottom: 10,
          offsetLeft: 8,
          offsetRight: 8
        }
      : {
          ...baseProps,
          animation: "fade",
          offsetTop: 40,
          offsetBottom: 0,
          offsetLeft: 40,
          offsetRight: 40
        };

  return (
    <ModalV2 {...props}>
      {(transitionState: TransitionState) => (
        <BetCancel transitionState={transitionState} />
      )}
    </ModalV2>
  );
};

export const BetSocialShareModal = ({
  isOpen,
  dispatch,
  device
}: {
  isOpen: boolean,
  dispatch: Dispatch<*>,
  device: string
}) => {
  const onClose = () => {
    dispatch(closeBetSocialShareModal());
  };

  const baseProps = {
    title: "Share Bet",
    titleType: "ipp",
    isOpen,
    onClose,
    qaLabel: "bet-social-share",
    hasShadow: true,
    hasOverlay: true,
    isFullWidth: false,
    isContentTransparent: false,
    isFullHeight: false,
    layerOffset: 1,
    hasContentMaxHeight: false
  };

  const props =
    device === "mobile"
      ? {
          ...baseProps,
          animation: "bottomFloating",
          offsetTop: 10,
          offsetBottom: 10,
          offsetLeft: 8,
          offsetRight: 8
        }
      : {
          ...baseProps,
          animation: "fade",
          offsetTop: 40,
          offsetBottom: 0,
          offsetLeft: 40,
          offsetRight: 40
        };

  return (
    <ModalV2 {...props}>
      {(transitionState: TransitionState) => (
        <BetSocialShare
          transitionState={transitionState}
          onClose={(e) => {
            if (e && e.preventDefault) e.preventDefault();
          }}
        />
      )}
    </ModalV2>
  );
};

export const ApproxPayoutHelpModal = ({
  isOpen,
  dispatch,
  device
}: {
  isOpen: boolean,
  dispatch: Dispatch<*>,
  device: string
}) => {
  const onClose = () => {
    dispatch(closeApproxPayoutModal());
  };

  const baseProps = {
    title: "Approximate Payout",
    titleType: "ipp",
    isOpen,
    onClose,
    qaLabel: "approximate-payout-help-modal",
    hasShadow: true,
    hasOverlay: true,
    isFullWidth: false,
    isContentTransparent: false,
    isFullHeight: false,
    layerOffset: 2
  };

  const props =
    device === "mobile"
      ? {
          ...baseProps,
          animation: "bottomFloating",
          offsetTop: 200,
          offsetBottom: 200,
          offsetLeft: 8,
          offsetRight: 8
        }
      : {
          ...baseProps,
          animation: "fade",
          offsetTop: 40,
          offsetBottom: 0,
          offsetLeft: 40,
          offsetRight: 40
        };

  return (
    <ModalV2 {...props}>
      {(transitionState: TransitionState) => (
        <ApproxPayoutHelp transitionState={transitionState} />
      )}
    </ModalV2>
  );
};

export const EmailReferralModal = ({
  isOpen,
  dispatch,
  device,
  history
}: {
  isOpen: boolean,
  dispatch: Dispatch<*>,
  device: Device,
  history: RouterHistory
}) => {
  const [isCloseDisable, setCloseDisable] = useState(false);
  useEffect(() => {
    const hash = history?.location?.hash || "";
    if (isOpen && !hash.includes("#invite")) {
      dispatch(closeEmailReferralModal());
    }
  }, [history?.location?.hash]);

  const onClose = () => {
    dispatch(closeEmailReferralModal());
  };

  const closeHandler = () => {
    if (!isCloseDisable) {
      onClose();
    }
  };

  const baseProps = {
    device,
    isOpen,
    onClose: closeHandler,
    onOverlayClick: closeHandler,
    hasOverlay: true,
    qaLabel: "email-referral",
    hasCloseButton: !isCloseDisable
  };

  const props =
    device === "mobile"
      ? {
          ...baseProps,
          isFullHeight: true,
          hasRoundedCorners: false,
          animation: "right",
          hasHeader: false,
          offsetTop: 0
        }
      : {
          ...baseProps,
          title: "Email Referral",
          titleType: "default",
          animation: "fade",
          isFullWidth: false,
          isFullHeight: false,
          hasHeader: true,
          offsetTop: 40,
          offsetBottom: 40,
          fixedWidth: "480px"
        };

  return (
    <ModalV2 {...props}>
      {() => (
        <EmailReferral
          device={device}
          onNavigationBackClick={onClose}
          onNavigationBackDisable={setCloseDisable}
        />
      )}
    </ModalV2>
  );
};

export default {
  MyBetsModal,
  BetPrefsModal,
  PromosModal,
  DepositsModal,
  PasswordRecoveryModal,
  SideSwipeModal,
  PendingWithdrawalsModal,
  ContentCardsModal,
  BetCancelModal,
  ApproxPayoutHelpModal,
  EmailReferralModal
};
