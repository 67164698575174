import { BaseEventProps } from "@urp/amplitude";

export enum ModuleEventProps {
  VIDEO_TITLE = "Video Title",
  VIDEO_PROVIDER = "Video Provider",
  VIDEO_TYPE = "Video Type",
  ERROR_TYPE = "Error Type"
}

export type EventProps = BaseEventProps & ModuleEventProps;

export enum MediatorEventType {
  VIEWED = "VP_VIDEO_VIEWED",
  PLAY_SUCCESS = "VP_VIDEO_PLAYED_SUCCESSFULL",
  CLICK_PLAY = "VP_VIDEO_PLAY_CLICKED",
  PAUSE = "VP_VIDEO_PAUSED",
  COMPLETE = "VP_VIDEO_PLAYED_COMPLETELY",
  MUTE_UNMUTE = "VP_VIDEO_MUTED_UNMUTED",
  SIZE_CHANGE = "VP_VIDEO_SIZE_CHANGED",
  SIZE_TILT = "VP_VIDEO_TILT_SIZE",
  ERROR = "VP_VIDEO_PLAY_ERROR",
  LOAD_FAIL = "VP_VIDEO_LOAD_FAILED"
}

export interface EventData {
  type: MediatorEventType;
  payload: {
    videoTitle: string;
    videoProvider: string;
    gaEventAction: string;
    errorType: string;
  };
}
