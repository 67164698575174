// @flow
import React from "react";
import SegmentedControl from "@tvg/atomic-ui/_molecule/SegmentedControl";
import TabGroup from "@tvg/atomic-ui/_molecule/TabGroup";
import type { Props } from "./types";
import { Header, Controllers, Filters } from "./styled-components";

const MyBetsHeaderTemplate = ({
  tabs,
  timeFrameTabs,
  shouldDisplayTimeFrameTabs
}: Props) => {
  return (
    <Header data-qa-label="myBets-headerTabs">
      <Controllers data-qa-label="myBets-controllers">
        <SegmentedControl tabs={tabs} withAnimation />
      </Controllers>
      {shouldDisplayTimeFrameTabs && (
        <Filters data-qa-label="myBets-filters">
          <TabGroup
            tabs={timeFrameTabs}
            stretch
            type="PILL"
            borderColor="blue"
            borderColorVariant="100"
          />
        </Filters>
      )}
    </Header>
  );
};

export default MyBetsHeaderTemplate;
